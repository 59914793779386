import { GetServerSideProps, NextPage } from 'next';
import dynamic from 'next/dynamic';
import { Article, WithContext } from 'schema-dts';

import {
  cacheControl,
  filterMarkets,
  getHrefLanguages,
} from '@hultafors/shared/helpers';

import { getApi, markets } from '@hultafors/snickers/api';
import { useOrganization, usePageMicroData } from '@hultafors/snickers/hooks';
import {
  ContentPageFragment,
  GlobalFields,
  PageProps,
  SeoField,
} from '@hultafors/snickers/types';

import { createMeta } from '../../../util/create-meta';

const ContentArea = dynamic(() =>
  import('@hultafors/snickers/components').then((mod) => mod.ContentArea),
);
const Page = dynamic(() =>
  import('@hultafors/snickers/components').then((mod) => mod.Page),
);
const MicroData = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.MicroData),
);

export interface ContentPageProps extends PageProps {
  content: ContentPageFragment;
  productsApiUrl?: string;
}

export const ContentPage: NextPage<ContentPageProps> = ({
  content,
  settings,
  hrefLanguages,
}) => {
  const pageMicroData = usePageMicroData({
    content: content?.contentArea,
    seo: content.seo,
    thing: {
      '@type': 'WebPage',
      description: content.seo?.description,
      name: content.seo?.title,
    },
  });
  const organization = useOrganization();
  const microdata: WithContext<Article> = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    author: organization,
    description: pageMicroData.description?.toString(),
    headline: pageMicroData.name?.toString() || '',
  };
  return (
    <Page
      metadata={createMeta(content?.seo as SeoField, settings?.language?.lang)}
      hrefLanguages={hrefLanguages}
      microData={pageMicroData}
    >
      <>
        <ContentArea content={content.contentArea} />
      </>
      <MicroData data={microdata} />
    </Page>
  );
};

export const getServerSideProps: GetServerSideProps<
  ContentPageProps,
  { slug: string; parentSlug?: string }
> = async ({ res, req, locale, params: { slug, parentSlug } }) => {
  try {
    const { settings, dato } = getApi(req, locale);

    const {
      data: { contentPage: content, ...globalFields },
    } = await dato.getContentPage(slug);

    if (!content || content === null) {
      return {
        notFound: true,
      };
    }

    if (
      parentSlug &&
      content.parentPageSlug &&
      parentSlug !== content.parentPageSlug.replace('/', '')
    ) {
      return {
        notFound: true,
      };
    }

    const hrefLanguages = getHrefLanguages(
      settings,
      filterMarkets(markets, 'SNICKERS'),
      content?.pageLocales,
    );

    res.setHeader('Cache-Control', cacheControl.page);
    return {
      props: {
        settings,
        ...(globalFields as GlobalFields),
        content,
        hrefLanguages,
      },
    };
  } catch (error) {
    console.error(error);
    return {
      notFound: true,
    };
  }
};

export default ContentPage;
